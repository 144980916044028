import './App.css';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import aniv from './80-aniversario.png';
import logoSorteo from './logo-sorteo.png';
import snte from './Logo SNTE 16.svg';
import mtroLogo from './Logo Maestro Leonel.svg';
import './background.css'; // Importa el archivo CSS de onda
import './button.css'; // Importa el archivo CSS del botón
import './search-bar.css'; // Importa el archivo CSS de la barra de búsqueda
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Message } from './message.jsx'; // Importa el componente Message

const loginData = {
  user: "Admin",
  password: "$dm1nSind!C4t."
};

function SearchForm() {
  const [rfc, setRfc] = useState('');
  const [token, setToken] = useState('');
  const [folio, setFolio] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const handleButtonClick = () => {
    setMessageOpen(false);
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
 
  const handleSearch = () => {
    setErrorMessage('');
    setSuccessMessage('');

    axios.post('https://api.sindicato.pitayasoft.mx/api/Login/acceso', loginData)
      .then(response => {
        console.log('Token:', response.data.token);
        setToken(response.data.token);
        const search = {
          RFC: rfc
        };
        const headers = {
          Authorization: `Bearer ${response.data.token}`, // Utiliza el token del estado
          'Content-Type': 'application/json' // Especifica el tipo de contenido como JSON
        };
  
        handleSearch2(search,headers);
      })
      .catch(error => {
        /*toast.error('Error al iniciar sesión');*/
        setErrorMessage('Error al iniciar sesión');
        setMessageOpen(true)
      });
  };

  const handleSearch2 = (search, headers) => {
    axios.post('https://api.sindicato.pitayasoft.mx/api/Usuarios/buscarRFC', search, { headers })
      .then(response => { 
        const folio = response.data.folio;
        setFolio(folio);
        setSuccessMessage(`Usuario registrado al sorteo con el folio: ${response.data.folio}`);
        setMessageOpen(true)
      })
      .catch(error => {
        /*toast.error('Usuario aun no registrado al sorteo');*/
        setErrorMessage('Usuario aún no registrado al sorteo, reportalo con tu coordinador de región');
        setMessageOpen(true)
      });
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    handleSearch();
    setMessageOpen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleSearch();
    }
  };

  const handleChange = (e) => {
    setRfc(e.target.value);
  };

  // Estado para almacenar el dato en caché
  const [cachedData, setCachedData] = useState(null);

  // Función para cargar el dato de la caché al inicio
  useEffect(() => {
    // Pre-cargar las imágenes al cargar la página
    const images = [aniv, logoSorteo];
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  // Función para guardar el dato en la caché
  const saveToCache = () => {
    const dataToCache = { example: 'cached data' };
    localStorage.setItem('cachedItem', JSON.stringify(dataToCache));
    setCachedData(dataToCache);
  };

  return (
    <div className="wave">
      <div style={{ position: 'absolute', top: 0, left: 7, /*left: 590,*/ 
        width: '100%', padding: '5px', marginBottom: '3px' }} 
        className='header-container'>
          <img src={aniv} alt="Image" style={{ width: '100px', height: '90px'}} />
      </div>
      
      <div className='LogoSorteo-container'>
        <img src={logoSorteo} alt="Image" 
        style={{ width: '100%', maxWidth: '250px', height: 'auto' }} />
      </div>
      
      <div className='err-ok-container'>
        <p className='message'>
          - Para validar su folio de participacion
        </p>
      </div>
      <div className='err-ok-container2'>
        <p className='message'>
          Ingresa tu RFC (con homonimia y sin espacios) -
        </p>
      </div>

      <div className='search-bar-container'> 
        <input type="text" value={rfc}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        className="sb-search-input"
        placeholder='Ingresa tu RFC'
        minLength={12} maxLength={13}/>
      </div>

      <div className='button-container'>
        <button onClick={toggleSearch} style={{borderRadius:'10px',
        height:'auto',width:'auto',padding:'8px 16px',fontSize:'18px',
        color:'white',border:'none',fontStyle:'normal',fontfamily:'FontAwesome'
        ,fontFamily: 'FontAwesome'}} className='ssb-button-background'>
          Validar Registro
        </button>
      </div>
      
      {messageOpen && (
        <Message onClose={handleButtonClick} 
        errorMessage={errorMessage} 
        successMessage={successMessage}>
        </Message>
      )}

      <div style={{ position: 'fixed', bottom: 0, left: 0, 
      width: '100%'}} 
      className='flor-container'>
        <img src={snte} /*className="App-logo"*/ alt="Image" 
        style={{ Width: '90px', height: '80px'}}/>
        <img src={mtroLogo} /*className="App-logo"*/ alt="Image" 
        style={{ Width: '100px', height: '140px'}}/>
      </div>
      
      <ToastContainer/>  
    </div>
  );
}

export default SearchForm;
