import {React,useEffect} from 'react';
import './message.css';
import './button.css'; 
import err from './error96.png';
import ok from './correcto.svg';

export const Message = ({onClose,errorMessage,successMessage}) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          onClose();
        }
      };

  // Función para cargar el dato de la caché al inicio
  useEffect(() => {
    // Pre-cargar las imágenes al cargar la página
    const images = [err, ok];
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return (
    <div className='modal-container' onKeyPress={handleKeyPress}>
        {errorMessage && 
            <div className='modal-error'>
                <div className='err-ok-container'>
                    <img src={err} alt="Image" style={{ width: '50px', height: '50px'}} />
                </div>
                <div className='modal-content'>
                    <div className='error-message'>
                        <p>
                            No cuentas con tu folio de participación, 
                            favor de contactar a tu Coordinador de Región  
                            o comunícate a los siguientes teléfonos
                        </p>
                        <div className='error-message-children'>
                            - Secretaría General:
                            <p style={{marginTop:'0px'}}>
                                3332504177 - WhatsApp
                            </p>
                            <p style={{marginTop:'-16px'}}>
                                3345353732 - fijo
                            </p >
                        </div>
                        <div className='error-message-children'>
                            - Secretaría Técnica de la Secretaría General:
                            <p style={{marginTop:'0px'}}>
                                3345353687
                            </p>
                        </div>
                        
                    </div>
                </div>
                <div className='modal-footer'> 
                    <button className='btn-submit' onClick={() => onClose()}>
                        Aceptar
                    </button>
                </div>
            </div>
        }
        {successMessage && 
            <div className='modal-succes'>
                <div className='err-ok-container'>
                    <img src={ok} alt="Image" style={{ width: '50px', height: '50px'}} />
                </div>
                <div className='modal-content'>
                    <div className='success-message'>
                        {successMessage}
                    </div>
                </div>
                <div className='modal-footer'> 
                    <button className='btn-submit' onClick={() => onClose()}>
                        Aceptar
                    </button>
                </div>
            </div>
        }
    </div>
  );
}